import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import dogIllustration from "../../images/dog-illustration.svg";

function CodeReview() {
  return (
    <Layout>
      <SEO
        keywords={[`hack guard`, `source code review`, `Cybersecurity`]}
        title="Application Penetration Testing"
      />

      <section className="flex flex-col items-center md:flex-row">
        <div className="md:w-2/3 md:mr-8">
          <h1 className="p-4 mb-4 border-b-4 border-teal-700 text-bold text-3xl">
            Source Code Review
          </h1>
          <p className="pl-4 font-serif leading-loose text-justify border-l-4 border-teal-700">
            Source code review helps you uncover hidden vulnerabilities, logic
            flaws, sensitive information leaks and ensures secure code standards
            are adhered to in your code base. the increasing complexity of your
            application introduces far more scope for vulnerabilities to creep
            into your code. we provide step-by-step manual examination as well
            as automated analysis of your software.
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default CodeReview;
